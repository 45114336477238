import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'src/auth/auth';
import { BehaviorSubject, from, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedInUser = new BehaviorSubject(null);
  public selectedSector = new BehaviorSubject(null);
  public selectedSubsector = new BehaviorSubject(null);
  public selectedBusinessActivity = new BehaviorSubject(null);
  public selectedClientRegion = new BehaviorSubject(null);
  public assignedConsultant = new BehaviorSubject(null);

  role: any = '';

  constructor(private router: Router) {}

  getAccessToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) => from(session.getIdToken().getJwtToken()))
    );
  }

  setSession(user: any) {
    let role =
      user?.signInUserSession?.idToken?.payload?.['cognito:groups']?.[0];
    window?.sessionStorage?.setItem('role', role);
    this.loggedInUser.next(user);
  }

  setSector(value: any) {
    this.selectedSector.next(value);
  }

  getSector(): Observable<any> {
    return this.selectedSector.asObservable();
  }

  setSubsector(value: any) {
    this.selectedSubsector.next(value);
  }

  getSubSector(): Observable<any> {
    return this.selectedSubsector.asObservable();
  }

  setBusinessActivity(value: any) {
    this.selectedBusinessActivity.next(value);
  }

  getBusinessActivity(): Observable<any> {
    return this.selectedBusinessActivity.asObservable();
  }

  setClientRegion(value: any) {
    this.selectedClientRegion.next(value);
  }

  getClientRegion(): Observable<any> {
    return this.selectedClientRegion.asObservable();
  }

  setAssignedConsultant(value: any) {
    this.assignedConsultant.next(value);
  }

  getAssignedConsultant(): Observable<any> {
    return this.assignedConsultant.asObservable();
  }

  getRole() {
    this.role = window?.sessionStorage?.getItem('role');
    return this.role;
  }

  public redirectUrl() {
    this.getRole() === 'consultants'
      ? this.router.navigate(['consultants/overview'])
      : this.router.navigate(['clients/landing-page']);
  }

  async signOut() {
    try {
      await Auth.signOut({ global: true });
      window?.sessionStorage?.clear();
      window?.localStorage?.clear();
      this.loggedInUser.next(null);
      this.router.navigate(['/login']);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
