// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  API_URL: 'https://api.dev.sandbox.rabofuturefit.nl/',
  awsConfig: {
    aws_project_region: 'eu-west-1',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_mGR0xx7Bi',
    aws_user_pools_web_client_id: '2jsn5dkkmiilbdv0lrkqcl6llf',
  },
  ssoClientId: '2fb739c9-24ad-4c7e-ad7b-9227bbc14eb7',
  ssoRedirectUri: 'https://www.dev.sandbox.rabofuturefit.nl/oauth-redirect',
  ssoAuthorizeUrl: 'https://login.microsoftonline.com/6e93a626-8aca-4dc1-9191-ce291b4b75a1/oauth2/v2.0/authorize',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
