import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'src/auth/auth';

import { from, Observable } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor } from './error.interceptor';

/**
 * This will append jwt token(AWS cognito token) for all the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private errorService: ErrorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        //Extracting the jwt token
        let jwtToken = auth?.getIdToken().getJwtToken();

        //Seeting the jwt token and content-type and accept to the headers
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
          Accept: 'application/json',
        });

        //Setting the api endpoint
        let url = `${environment.API_URL}${request.url}`;

        //Setting the headers and the api url to the api request
        let with_auth_request = request.clone({
          headers,
          url,
        });

        return next.handle(with_auth_request);
      })
    );
  }
}
